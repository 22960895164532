import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["unreadMessagesCount", "incomingMessages"]

  connect() {
    this.subscription = consumer.subscriptions.create("TextMessagesChannel", {
      received: (data) => {
        this.updateNotificationCount(data.count)
        this.replaceMessages(data.message_html)
      }
    })
  }

  updateNotificationCount(count) {
    if (this.hasUnreadMessagesCountTarget) {
      this.unreadMessagesCountTarget.textContent = count
    }
  }

  replaceMessages(messageHtml) {
    if (this.hasIncomingMessagesTarget) {
      this.incomingMessagesTarget.innerHTML = ""
      this.incomingMessagesTarget.insertAdjacentHTML("beforeend", messageHtml)
    }
  }

  disconnect() {
    this.subscription.unsubscribe()
  }
}